if(window.MercadoPagoComponent){
    /* global MercadoPagoComponent, axios*/
    class EcommerceMPFormComponent extends MercadoPagoComponent {

        static name(){
            return "mercadopagoFormCustom";
        }

        static mounted(){
            let superMounted = super.mounted;
            return async function (){
                await superMounted();
                this.$nextTick(()=>{
                    this.customdoPay();
                });
            };
        }

        static getMethods() {
            let parentMetothds = super.getMethods();

            parentMetothds.customdoPay = function (event){
                if(this.$store.state.screenState)
                    this.$store.dispatch('toogleLockScreen',null);
                this.$store.dispatch('toogleLockScreen',"Redirecting to Mercado Pago...");
                axios.post("/ecommerce/api/checkStock",{order:this.salesorder}).then((response)=>{
                    if(response.data.ok){
                        axios.post("/mercadopago/get_preferences",{
                            recordType: btoa("SalesOrder"),
                            recordId:btoa(this.info.salesorder),
                            appName:btoa("ecommerce"),
                            appRedirectUrl:btoa(window.location.origin + "/ecommerce/payments/result")
                        })
                            .then((response)=>{
                                if(response.data.id) {
                                    window.location.replace(response.data.init_point);
                                    //window.location.href=response.data.init_point;
                                    /*let mercadoPagoCheckOut = document.createElement("script");
                                    mercadoPagoCheckOut.setAttribute("src", "https://www.mercadopago.com.ar/integrations/v1/web-payment-checkout.js");
                                    mercadoPagoCheckOut.setAttribute("data-preference-id", response.data.id);
                                    document.getElementById("mp-web-checkout").appendChild(mercadoPagoCheckOut);
                                    */
                                }
                                else{
                                    if(this.$store.state.screenState)
                                        this.$store.dispatch('toogleLockScreen',null);
                                }
                            });
                    }
                    else{
                        let res = {};
                        let processError = [];
                        let err = response.data.error[0];
                        for(let item of (err.params.webmessage ? err.params.webmessage : err.params)) {
                            let errorData = [err.code];
                            let dataError = item.split("*-*-*");
                            let storeItem = this.$store.getters.getItemByCode(dataError[0]);
                            errorData.push(storeItem.Name);
                            errorData.push(dataError[1]);
                            processError.push({description:this.tr(errorData)});
                        }
                        res.error = true;
                        res.cause = processError;
                        this.$emit('payment-result', res);
                        this.$store.dispatch('toogleLockScreen',"FFF");
                    }
                });
            };

            return parentMetothds;
        }

        static getTemplate() {
            return `<button class="btn btn-primary" type="button" disabled>
                      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      Redirecting to Mercado Pago...
                    </button>`;
        }

    }
    Vue.component('mercadopagoFormCustom', EcommerceMPFormComponent.getComponent());
    window.EcommerceMercadoPagoFormComponent = EcommerceMPFormComponent;
}
